import $ from 'jquery';

var injected = false;
var loaded = false;
var callbacks = [];

function loadHcaptcha() {
  return new Promise((resolve, reject) => {
    if(loaded) return resolve();
    callbacks.push(resolve);
    if(injected) return;
    injected = true;

    window.hcaptchaLoaded = () => {
      loaded = true;

      for(const element of callbacks) {
        element();
      }
    }

    let script = document.createElement('script');
    script.setAttribute('src', 'https://hcaptcha.com/1/api.js?onload=hcaptchaLoaded&render=explicit&recaptchacompat=off')
    script.async = true;
    document.head.appendChild(script);
  })
}

function hcaptcha_invisible(config) {
  return new Promise((resolve, reject) => {
    loadHcaptcha().then(() => {
      let node = document.createElement("div");
      document.body.appendChild(node);
      try {
        let widgetID = hcaptcha.render(node, {
          'sitekey': config.sitekey,
          'size': 'invisible',
          'callback': (token) => {
            resolve(token);
            document.body.removeChild(node);
          },
          'error-callback': (error) => {
            reject(new Error(`Failed to run no-bot verification (hCaptcha). Is there an issue with your internet connection? (${error})`))
          },
          'close-callback': () => {
            let e = new Error(`Please verify you are not a bot by completing the puzzle.`)
            e.event = 'close';
            reject(e)
          }
        });
        hcaptcha.execute(widgetID);
      } catch(error) {
        reject(new Error(`Failed to render no-bot verification (hCaptcha). Is there an issue with your internet connection? (${error})`))
      }
    }).catch((error) => {
      reject(new Error(`Failed to load no-bot verification (hCaptcha). Is there an issue with your internet connection? (${error})`))
    });
  });
}

function hcaptcha_invisible_submit(form, config) {
  return new Promise((resolve, reject) => {
    if($(form).data("hcaptcha-active")) return;
    $(form).data("hcaptcha-active", true);

    hcaptcha_invisible(config).then((captcha) => {
      let field = $(form).find("input[name='hcaptcha_response']");
      if(field.length == 0) {
        field = $("<input type='hidden' name='hcaptcha_response' />")
        field.appendTo(form);
      }
      field.val(captcha)
      form.submit();
      $(form).data("hcaptcha-active", false);
      resolve();
    }).catch((error) => {
      if(error.event != "close") alert(error.message);
      $(form).data("hcaptcha-active", false);
      reject(new Error(error.message));
    })
  })
}

function hcaptcha_checkbox(config) {
  return new Promise((resolve, reject) => {
    loadHcaptcha().then(() => {
      try {
        hcaptcha.render(config.element, {
          'sitekey': config.sitekey,
          'callback': (token) => {
            resolve(token);
          },
          'error-callback': (error) => {
            reject(new Error(`Failed to run no-bot verification (hCaptcha). Is there an issue with your internet connection? (${error})`))
          }
        });
      } catch(error) {
        reject(new Error(`Failed to render no-bot verification (hCaptcha). Is there an issue with your internet connection? (${error})`))
      }
    }).catch((error) => {
      reject(new Error(`Failed to load no-bot verification (hCaptcha). Is there an issue with your internet connection? (${error})`))
    });
  });
}

$(() => {
  $(".hcaptcha-form").on("submit", function (e) {
    e.preventDefault();
    let submit = $(this).find(".hcaptcha-submit");
    submit.attr("disabled", true);
    hcaptcha_invisible_submit(this, { sitekey: $(this).data("hcaptcha-sitekey") })
      .then(() => {
        submit.attr("disabled", false);
      })
      .catch((err) => {
        console.error(err);
        submit.attr("disabled", false);
      });
  });
});

export {
  hcaptcha_invisible,
  hcaptcha_invisible_submit,
  hcaptcha_checkbox,
};
