import * as Sentry from "@sentry/browser";

Sentry.init({
  integrations: [
    Sentry.replayIntegration(),
  ],
  replaysSessionSampleRate: 0.01, // 1% of sessions will have replay
  replaysOnErrorSampleRate: 1.00, // 100% of errors will have replay
  ignoreErrors: [
    // Random plugins/extensions
    "top.GLOBALS",
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    "originalCreateNotification",
    "canvas.contentDocument",
    "MyApp_RemoveAllHighlights",
    "http://tt.epicplay.com",
    "Can't find variable: ZiteReader",
    "jigsaw is not defined",
    "ComboSearch is not defined",
    "http://loading.retry.widdit.com/",
    "atomicFindClose",
    // Facebook borked
    "fb_xd_fragment",
    // Tencent
    "tencent_ug_bridge",
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
    // reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268
    "bmi_SafeAddOnload",
    "EBCallBackMessageReceived",
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    "conduitPage",
    // See https://github.com/getsentry/sentry-javascript/issues/7842#issuecomment-1506950844
    "ResizeObserver loop limit exceeded",
    "ResizeObserver loop completed with undelivered notifications",
    "window.webkit.messageHandlers",
    "avast",
    "file://",
    "translate.goog",
    "zaloJSV2",
    "ceCurrentVideo",
    "instantSearchSDKJSBridgeClearHighlight",
    // https://github.com/getsentry/sentry-javascript/issues/7941#issuecomment-1815927662
    "Event `ErrorEvent` captured as exception with message `Script error.`",
    "WeixinJSBridge",
  ],
  denyUrls: [
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    /^chrome-extension:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    /trans\.xdtsmart\.com\//i,
  ],
  beforeSend(event, hint) {
    const error = hint.originalException || hint.syntheticException || event;
    let errorMessage = "";
    if (typeof error === 'string') {
      errorMessage = error
    } else if (error instanceof Error) {
      errorMessage = error.message
    } else if (error instanceof ErrorEvent) {
      errorMessage = error.message
    }

    try {
      if (event?.exception?.values[0]?.stacktrace?.frames?.length === 0) {
        return null;
      }

      if (event?.exception?.values[0]?.stacktrace?.frames[0]?.filename === `<anonymous>`) {
        return null;
      }

      // Filter out nonsensical errors that are less than 15 characters
      if (errorMessage.length < 15) {
        return null;
      }
    } catch (oO) {}


    return event;
  },
});

window.Sentry = Sentry;
