<template>
  <div
    ref="lecBanner"
    id="lecBanner"
    class="lec-alert alert text-left fixed-top alert-dismissible fade show"
    role="alert"
  >
    <span v-html="message"></span>

    <button
      type="button"
      class="close"
      data-dismiss="alert"
      aria-label="Close"
      @click="close"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</template>

<script>
import Rails from "@rails/ujs";
export default {
  props: {
    message: String
  },
  data() {
    return {
      height: 0,
      navbar: null
    };
  },
  mounted() {
    this.height = this.$refs.lecBanner.clientHeight;
    this.navbar = document.querySelector(".navbar");
    this.navbar.style.top = `${this.height}px`;
  },
  methods: {
    close() {
      this.navbar.style.top = "0px";
      fetch("/session_preferences/set", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": Rails.csrfToken()
        },
        body: JSON.stringify({
          key: "lec_banner_dismissed",
          value: true
        })
      });
    }
  }
};
</script>
<style>
.lec-alert {
  background-color: black !important;
  color: white !important;
}
.lec-alert.rounded-none {
  border-radius: 0 !important;
}
.lec-alert a {
  color: white !important;
  font-weight: bold !important;
  text-decoration: none !important;
}
.lec-alert button {
  color: white !important;
  opacity: 1 !important;
}
</style>
